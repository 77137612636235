import Button from '@/components/shared/Button';
import FilterSVG from '@/svg/filter.svg';
import SearchSVG from '@/svg/search.svg';
import CompareSVG from '@/svg/compare-empty-black.svg';
import SortSVG from '@/svg/sort.svg';
import FavoriteSVG from '@/svg/favorites-empty-black.svg';
import { useSetOpenedPopupsState } from '@/atoms/opened-popups';
import SortPopup, { SORT_POPUP_NAME } from './SortPopup';
import FiltersPopup, { FILTERS_POPUP_NAME } from './FiltersPopup';
import { ApiBrandsResponse, Brand, useBrandsQuery } from '@/api/brands';
import { ApiMaterialsResponse, Material, useMaterialsQuery } from '@/api/materials';
import { CategoryId } from '@/api/categories';
import { HTMLAttributes, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import SearchPopup from '../SearchPopup/SearchPopup';
import { PresetFilterItem } from '@/types';
import { FilterFields } from '@/api/productFilters';
import { FAVORITES_POPUP_NAME } from '@/components/shared/FavoritesPopup/FavoritesPopup';
import { SMALL_SEARCH_POPUP_NAME } from '@/components/layout/CatalogFilters/SmallSearchPopup';
import classNames from 'classnames';
import Link from '@/components/shared/Link';
import { useFiltersVisible, useFiltersVisibleState } from '@/atoms/search-popup-visible';

interface Props extends HTMLAttributes<HTMLElement> {
    brands?: Brand[];
    materials?: Material[];
    category?: CategoryId;
    initialMaterialsResponse?: ApiMaterialsResponse;
    initialBrandsResponse?: ApiBrandsResponse;
    filters?: Partial<FilterFields>;
    presetFilters?: PresetFilterItem[];
}

const CatalogFilters = ({
    brands,
    materials,
    category,
    initialMaterialsResponse,
    initialBrandsResponse,
    presetFilters,
    filters,
    ...props
}: Props) => {
    const { openPopup } = useSetOpenedPopupsState();
    const [currentCategory, setCategory] = useState<CategoryId>(category ?? 1);
    const filtersVisible = useFiltersVisible();
    const setFiltersVisible = useFiltersVisibleState();

    const router = useRouter();

    const materialsQuery = useMaterialsQuery({
        queryParams: {
            category: currentCategory,
        },
        initialData: initialMaterialsResponse,
    });

    const brandsQuery = useBrandsQuery({
        queryParams: {
            category: currentCategory,
        },
        initialData: initialBrandsResponse,
    });

    useEffect(() => {
        if (router.query.category) {
            setCategory(Number(router.query.category) as CategoryId);
        }
    }, [router.query]);

    return (
        <>
            <nav
                {...props}
                className={classNames(
                    'catalog-filters',
                    { 'catalog-filters--hidden': !filtersVisible },
                    props.className,
                )}
            >
                <Button
                    variant="primary"
                    className="catalog-filter-btn catalog-filter-btn--filter"
                    onClick={() => {
                        openPopup(FILTERS_POPUP_NAME);
                    }}
                    size="sm"
                    icon={<FilterSVG />}
                >
                    Фильтр
                </Button>
                <Button
                    variant="primary"
                    className="catalog-filter-btn catalog-filter-btn--search"
                    icon={<SearchSVG />}
                    size="sm"
                    onClick={() => {
                        setFiltersVisible(false);
                        openPopup(SMALL_SEARCH_POPUP_NAME);
                    }}
                >
                    Поиск
                </Button>
                <Button
                    tag={Link}
                    href="/compare"
                    variant="primary"
                    className="catalog-filter-btn catalog-filter-btn--compare"
                    size="sm"
                    icon={<CompareSVG />}
                >
                    Сравнение
                </Button>
                <Button
                    variant="primary"
                    className="catalog-filter-btn catalog-filter-btn--sort"
                    onClick={() => {
                        openPopup(SORT_POPUP_NAME);
                    }}
                    size="sm"
                    icon={<SortSVG />}
                >
                    Сортировка
                </Button>
                <Button
                    variant="primary"
                    className="catalog-filter-btn catalog-filter-btn--favorite"
                    onClick={() => {
                        openPopup(FAVORITES_POPUP_NAME);
                    }}
                    size="sm"
                    icon={<FavoriteSVG />}
                >
                    Избранное
                </Button>
            </nav>
            {brands && materials && filters && presetFilters && (
                <FiltersPopup
                    brands={brandsQuery.data?.data ?? brands}
                    materials={materialsQuery.data?.data ?? materials}
                    filters={filters}
                    presetFilters={presetFilters}
                />
            )}
            <SortPopup
                methods={[
                    {
                        name: 'новые публикации',
                        value: 'published_at',
                        postfix: 'desc',
                    },
                    {
                        name: 'старые публикации',
                        value: 'published_at',
                        postfix: 'asc',
                    },
                    {
                        name: 'по убыванию цены',
                        value: 'price',
                        postfix: 'desc',
                    },
                    {
                        name: 'по возрастанию цены',
                        value: 'price',
                        postfix: 'asc',
                    },
                ]}
            />
            <SearchPopup />
        </>
    );
};

export default CatalogFilters;
