import { useQuery } from '@tanstack/react-query';
import { axiosAPI } from './axios-instance';
import { ApiResponse } from '@/types';
import { CategoryId } from './categories';

export type ApiMaterialsRequest = {
    category?: CategoryId;
};

export type Material = {
    id: number;
    slug: string;
    name: string;
};

export type ApiMaterialsResponse = ApiResponse<Material[]>;

export const getMaterials = ({ params, signal }: { params: ApiMaterialsRequest; signal?: AbortSignal }) =>
    axiosAPI.get<ApiMaterialsResponse>(`/v1/materials`, { params, signal }).then((res) => res.data);

export const useMaterialsQuery = ({
    queryParams,
    initialData,
}: {
    queryParams?: ApiMaterialsRequest;
    initialData?: ApiMaterialsResponse;
} = {}) =>
    useQuery({
        queryKey: ['materials', queryParams],
        queryFn: ({ signal }) => getMaterials({ params: { category: queryParams?.category }, signal }),
        keepPreviousData: true,
        initialData,
    });
