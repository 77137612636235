import { axiosAPI } from './axios-instance';
import { ApiResponse, ImageShape } from '@/types';
import { CategoryId } from './categories';
import { useQuery } from '@tanstack/react-query';

export type ApiBrandsRequest = {
    category?: CategoryId;
};

export type Brand = {
    id: number;
    slug: string;
    name: string;
    highlighted: boolean;
    category_id: CategoryId;
    svg: ImageShape | null;
};

export type ApiBrandsResponse = ApiResponse<Brand[]>;

export const getBrands = ({ params, signal }: { params?: ApiBrandsRequest; signal?: AbortSignal } = {}) =>
    axiosAPI.get<ApiBrandsResponse>(`/v1/brands`, { params, signal }).then((res) => res.data);

export const useBrandsQuery = ({
    queryParams,
    initialData,
}: {
    queryParams?: ApiBrandsRequest;
    initialData?: ApiBrandsResponse;
} = {}) =>
    useQuery({
        queryKey: ['brands', queryParams],
        queryFn: ({ signal }) => getBrands({ params: { category: queryParams?.category }, signal }),
        keepPreviousData: true,
        initialData,
    });
