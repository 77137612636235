import { FormEvent, forwardRef, useEffect, useState } from 'react';
import Input from '../Input';
import { Props as InputProps } from '../Input/Input';
import SearchSVG from '@/svg/form-search.svg';
import CrossSVG from '@/svg/search-cross.svg';
import Button from '../Button';
import { m, AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { useFiltersState } from '@/atoms/catalog/filters-state';
import { produce } from 'immer';
import { useSetOpenedPopupsState } from '@/atoms/opened-popups';
import { SMALL_SEARCH_POPUP_NAME } from '@/components/layout/CatalogFilters/SmallSearchPopup';
import { useMediaQuery } from 'usehooks-ts';

const MotionButton = m(Button);

const SearchInput = forwardRef<
    HTMLInputElement,
    InputProps & { onSubmit?: () => void; isBigSubmitBtn?: boolean; label?: string }
>(({ value, defaultValue, showLabel, onSubmit, isBigSubmitBtn = false, label = 'Поиск товара', ...props }, ref) => {
    const { closePopup } = useSetOpenedPopupsState();
    const [currentValue, setCurrentValue] = useState<string>((defaultValue as string) || '');
    const router = useRouter();
    const [, setFiltersState] = useFiltersState();
    const isMobile = useMediaQuery('(max-width: 767px)');

    useEffect(() => {
        if (value && typeof value === 'string') {
            setCurrentValue(value);
        }
    }, [value]);

    return (
        <form
            onSubmit={(event: FormEvent) => {
                event.preventDefault();
                onSubmit?.();
                const { category, sort, order } = router.query;
                const search = currentValue.trim() !== '' ? currentValue : undefined;

                router.push(
                    {
                        pathname: '/search',
                        query: {
                            ...router.query,
                            category: category ?? 1,
                            sort: sort ?? 'published_at',
                            order: order ?? 'desc',
                            search,
                        },
                    },
                    undefined,
                    { shallow: true },
                );

                setFiltersState(
                    produce((draft) => {
                        draft.search = search;
                    }),
                );

                setTimeout(() => {
                    closePopup(SMALL_SEARCH_POPUP_NAME);
                }, 500);
            }}
            className="search-input-form"
        >
            <label className="search-input-label">
                <Input
                    {...props}
                    label={label}
                    inputClass="search-input js-search-input-el"
                    tag="span"
                    showLabel={showLabel}
                    value={currentValue}
                    onChange={(event: FormEvent) => {
                        const eventTarget = event.target as HTMLInputElement;
                        if (eventTarget) {
                            if (typeof eventTarget.value === 'string') {
                                setCurrentValue(eventTarget.value);
                            }
                        }
                    }}
                    className={props.className}
                    ref={ref}
                />
            </label>
            <div className="search-input-buttons">
                <AnimatePresence mode="wait">
                    {currentValue.length > 0 && (
                        <MotionButton
                            type="button"
                            className="search-input-clear"
                            aria-label="Очистить строку поиска"
                            icon={<CrossSVG />}
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            onClick={() => {
                                setCurrentValue('');
                            }}
                        />
                    )}
                </AnimatePresence>
                {isBigSubmitBtn && !isMobile ? (
                    <Button geometryVariant="mustache" type="submit" className="search-input-submit">
                        Найти
                    </Button>
                ) : (
                    <Button type="submit" className="search-input-submit" icon={<SearchSVG />} />
                )}
            </div>
        </form>
    );
});
SearchInput.displayName = 'SearchInput';

export default SearchInput;
