import '../css/base/_fonts.scss';
import '../css/app.scss';
import { ReactNode, useEffect } from 'react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Providers from '@/components/layout/Providers';
import LayoutGrid from '@/components/utils/LayoutGrid';
import vhMobileFix from '@/utils/vh-mobile-fix';
import { calculateScrollbarWidth } from '@/utils/calculate-scrollbar-width';
import { usePrevious } from '@/hooks/use-previous';
import AppInits from '@/components/general/AppInits';
import AppHead from '@/components/general/AppHead';
import { CommonPageProps } from '@/types';
import Preloader from '@/components/general/Preloader';
import { usePageTransitionState } from '@/atoms/page-transition';
import { AnimatePresence } from 'framer-motion';
import { deleteGetParams, deleteHash } from '@/utils/strings';
import { usePathname } from 'next/navigation';
import { YM_ID } from '@/metrics/ym';
import dynamic from 'next/dynamic';
import { useMediaQueryDeviceValue } from '@/atoms/media-query-device';
import CatalogFilters from '@/components/layout/CatalogFilters';
import SmallSearchPopup from '@/components/layout/CatalogFilters/SmallSearchPopup';

const Header = dynamic(() => import('@/components/layout/Header'));
const Footer = dynamic(() => import('@/components/layout/Footer'));
const Curtain = dynamic(() => import('@/components/general/Curtain/Curtain'));
const FavoritesPopup = dynamic(() => import('@/components/shared/FavoritesPopup'));
const CatalogLandingPopup = dynamic(() => import('@/components/general/CatalogLandingPopup'));
const FeedbackPopup = dynamic(() => import('@/components/general/FeedbackPopup'));
const Cursor = dynamic(() => import('@/components/layout/Cursor'));
const CookieBanner = dynamic(() => import('@/components/general/CookieBanner'));
const CallbackAndPhoneButtons = dynamic(() => import('@/components/general/CallbackAndPhoneButtons'));

const AnimatedPage = ({ pageProps, children }: { pageProps: CommonPageProps; children: ReactNode }) => {
    const prevBodyClass = usePrevious(pageProps.bodyClass);
    const [{ mode }] = usePageTransitionState();

    return (
        <AnimatePresence
            mode={mode}
            onExitComplete={() => {
                if (prevBodyClass) {
                    document.documentElement.classList.remove(...prevBodyClass.split(' '));
                }

                if (pageProps.bodyClass) {
                    document.documentElement.classList.add(...pageProps.bodyClass.split(' '));
                }

                window.scrollTo({ top: 0, behavior: 'auto' });
                document.dispatchEvent(new Event('new-page-ready'));
                document.dispatchEvent(new Event('clear-filters'));
            }}
        >
            {children}
        </AnimatePresence>
    );
};

const CursorWrapper = () => {
    const [device] = useMediaQueryDeviceValue();

    return device === 'desktop' && <Cursor />;
};

if (typeof window !== 'undefined') {
    document.documentElement.classList.add('js-ready');
    vhMobileFix();
    calculateScrollbarWidth();
}

const App = ({ Component, pageProps }: AppProps<CommonPageProps>) => {
    const router = useRouter();
    const prevBodyClass = usePrevious(pageProps.bodyClass);
    const pathname = usePathname();

    useEffect(() => {
        (window as any).ym?.(YM_ID, 'hit', pathname);
    }, [pathname]);

    /**
     * Смена класса на <html> при переходах между страницами
     */
    useEffect(() => {
        const onNewPageReady = () => {
            if (prevBodyClass) {
                document.documentElement.classList.remove(...prevBodyClass.split(' '));
            }

            if (pageProps.bodyClass) {
                document.documentElement.classList.add(...pageProps.bodyClass.split(' '));
            }
        };

        document.addEventListener('new-page-ready', onNewPageReady);

        return () => {
            document.removeEventListener('new-page-ready', onNewPageReady);
        };
    }, [pageProps.bodyClass, prevBodyClass]);

    useEffect(() => {
        history.scrollRestoration = 'manual';
    }, []);

    return (
        <Providers>
            <AppInits course={pageProps.course} />
            <AppHead meta={pageProps.meta} />
            <Preloader />
            <Header />
            <Curtain />
            <main className="main">
                <AnimatedPage pageProps={pageProps}>
                    <Component
                        {...pageProps}
                        key={deleteHash(deleteGetParams(router.asPath)) + (router.query.category ?? '')}
                    />
                </AnimatedPage>
            </main>
            <FavoritesPopup />
            <CatalogLandingPopup categories={pageProps.categories} />
            <FeedbackPopup />
            <SmallSearchPopup />
            <Footer
                socnets={pageProps.socnets}
                footerText={pageProps.footerText}
                footerEmail={pageProps.footerEmail}
                footerPhone={pageProps.footerPhone}
            />
            <CursorWrapper />
            <CookieBanner />
            <CatalogFilters className="catalog-filters--all-pages" />
            <CallbackAndPhoneButtons
                socnets={pageProps.socnets}
                phone={pageProps.footerPhone}
                subscribeLink={pageProps.subscribeLink}
            />

            {process.env.NODE_ENV === 'development' && <LayoutGrid />}
        </Providers>
    );
};

export default App;
