import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { axiosAPI } from './axios-instance';
import { ApiResponse, Product } from '@/types';

export type ApiProductsRequest = Partial<{
    id?: string;
    limit: number;
    page: number;
    category: number;
    search: string | string[];
    popularity: 1 | 0;
    'brands[]': number[];
    priceMin: number;
    priceMax: number;
    powerMin: number;
    powerMax: number;
    diameterMin: number;
    diameterMax: number;
    available: 1 | 0;
    'materials[]': number[];
    'bracelet[]': string[];
    'mechanism[]': string[];
    'options[]': string[];
    'shapes[]': string[];
    'state[]': number[];
    order: string;
    sort: string;
    'type[]': string[];
    'color[]': string[];
    'insert[]': string[];
    'collection[]': string[];
    'dial_color[]': string[];
    'genders[]': number[];
    'other_size[]': string[];
    ids?: number[];
    show_characteristics?: 1 | 0;
}>;

export type ApiProductsResponse = ApiResponse<{
    totalCount: number;
    items: Product[];
    filter?: {
        priceMin: number;
        priceMax: number;
        diameterMin?: number;
        diameterMax?: number;
        type?: string[];
        color?: string[];
        insert?: string[];
        collection?: string[];
    };
}>;

export const getProducts = ({ params, signal }: { params?: ApiProductsRequest; signal?: AbortSignal } = {}) =>
    axiosAPI.get<ApiProductsResponse>(`/v4/products`, { params, signal }).then((res) => res.data);

export const useProductsQuery = ({
    queryParams,
    initialData,
    callback,
    enabled,
}: {
    queryParams?: ApiProductsRequest;
    initialData?: ApiProductsResponse;
    callback?: () => void;
    enabled?: boolean;
} = {}) =>
    useQuery({
        queryKey: ['products', queryParams],
        queryFn: ({ signal }) => getProducts({ params: queryParams, signal }),
        keepPreviousData: true,
        initialData,
        placeholderData: initialData,
        onSettled: () => {
            callback?.();
        },
        enabled,
    });

export const useProductsInfiniteQuery = ({
    params,
    initialData,
}: {
    params: ApiProductsRequest;
    initialData?: ApiProductsResponse;
}) => {
    return useInfiniteQuery({
        queryKey: ['products', params],
        queryFn: ({ signal, pageParam = params.page ?? 1 }) =>
            getProducts({
                params: { ...params, page: pageParam },
                signal,
            }),
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage?.data?.totalCount) {
                return undefined;
            }

            const initialPage = params.page ?? 1;
            const diff = initialPage - allPages.length;
            const transformedAllPages = diff > 0 ? [...Array(initialPage - 1).fill(null), ...allPages] : allPages;
            const nextPage = transformedAllPages.length + 1;

            return nextPage * (params.limit || 1) < lastPage.data.totalCount ? nextPage : undefined;
        },
        initialData: initialData
            ? {
                  pages: [initialData],
                  pageParams: [params.page ?? 1],
              }
            : undefined,
        keepPreviousData: true,
        cacheTime: 0,
        staleTime: 0,
    });
};
